export default function workspaceLoading({ $auth, route }) {
  const user = $auth?.user;
  const workspaceSetup = user?.workspace?.workspace_setup;
  const localePath = useLocalePath();

  if (!$auth?.loggedIn) return null;

  if (workspaceSetup === false) {
    navigateTo(localePath({
      name: 't-tenantSlug-loading',
      params: route.params,
      query: route.query,
    }));
  }

  return null;
}
